import { useTranslation } from "react-i18next";

import { useEventBus } from "../../features/event-bus/use-event-bus";
import { useSessionStore } from "../../store/SessionStore";
import { useUserLogOut } from "./use-user-log-out";

export function useUserEventBusEvents() {
  // fixme remove header binding
  const { t } = useTranslation("header");
  const session = useSessionStore();
  const userLogOut = useUserLogOut();
  useEventBus(["PARTNER_KEY_CHANGED", "DELETE"], async (message) => {
    if (message?.event === "DELETE") {
      if (
        message?.partId ||
        (session?.accId && Number(session?.accId) === message?.accId)
      ) {
        await userLogOut({ message: `${t("DELETE_PARTID_ACCID")}.` });
      }
    }
    if (message?.event === "PARTNER_KEY_CHANGED") {
      await userLogOut({ message: `${t("PARTNER_KEY_CHANGED")}.` });
    }
  });
}
