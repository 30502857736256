import { css } from "@emotion/react";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import { api } from "../../api/requests";
import { useSessionsDefaultFiltersOptions } from "../../entities/session/use-sessions-default-filters-options";
import { useUserRole } from "../../entities/user/use-user-role";
import { FilterCheckBox } from "../../shared/ui/filter-check-box";
import {
  formatNumberWithSpaces,
  formatSessionTime,
  smoothScrollToBottom,
} from "../../shared/utils/utils";
import { formatDateShort } from "../../shared/utils/utils";
import { useSessionStore } from "../../store/SessionStore";
import { useSessionPageStore } from "../../store/session-page-store";
import { SessionsAccount } from "./filters/sessions-account";
import { SessionsDateEnded } from "./filters/sessions-date-ended";
import { SessionsDateStarted } from "./filters/sessions-date-started";
import { SessionsPartId } from "./filters/sessions-part-id";
import { SessionsRoom } from "./filters/sessions-room";
import { SessionsRoomType } from "./filters/sessions-room-type";

function SessionsPage() {
  const { t } = useTranslation("sessions");
  const sessionPageStore = useSessionPageStore();

  const {
    loading,
    getAllowedRooms,
    fetchAccounts,
    fetchSessions,
    searchParams,
    setSearchParam,
    setSearchParams,
  } = useSessionPageStore();

  const role = useUserRole();
  const isAgentOrSubagent = role === "AGENT" || role === "SUBAGENT";

  const defaults = useSessionsDefaultFiltersOptions();

  const { currentUser } = useSessionStore();
  const { currentPage, totalPages } = sessionPageStore;

  // fixme refactor
  const getFileForDownload = useCallback(async () => {
    try {
      const response = await api.dwnlSessionsReq({
        partIds: [searchParams?.partIds],
        dateStarted: formatDateShort(searchParams?.dateStarted),
        dateEnded: formatDateShort(searchParams?.dateEnded),
      });
      let fileData;
      if (typeof response?.data === "string") {
        fileData = new Blob([response?.data], { type: "text/csv" });
      } else {
        fileData = await response?.data?.blob();
      }
      const fileUrl = window.URL.createObjectURL(fileData);
      const downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.download = `${formatDateShort(
        searchParams?.dateStarted
      )} - ${formatDateShort(searchParams?.dateEnded)}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  }, [searchParams?.dateEnded, searchParams?.dateStarted, searchParams?.partIds]);

  const simpleBarRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const scrollElement = simpleBarRef.current.getScrollElement();
    smoothScrollToBottom(scrollElement);
  }, []);

  const loadMore = useCallback(async () => {
    if (currentPage < totalPages - 1) {
      await fetchSessions({ page: currentPage + 1 });
      setTimeout(() => {
        scrollToBottom();
      }, 5);
    }
  }, [currentPage, fetchSessions, scrollToBottom, totalPages]);

  useEffect(() => {
    if (searchParams?.partIds) {
      getAllowedRooms(searchParams?.partIds);
      fetchAccounts(searchParams?.partIds);
    } else {
      setSearchParams({
        partIds: currentUser,
        // dateStarted: defaults?.dateStarted?.filter,
        // dateEnded: defaults?.dateEnded?.filter,
      });
    }
  }, [
    currentUser,
    fetchAccounts,
    getAllowedRooms,
    searchParams?.partIds,
    setSearchParams,
  ]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions, searchParams]);

  return (
    <SimpleBar
      ref={simpleBarRef}
      className="content sessions"
      forceVisible="y"
      autoHide={false}
    >
      <div style={{ position: "relative" }}>
        <div className="table-filters">
          <div className="table-filters-l">
            <SessionsPartId name="partIds" placeholder="Partner" />
            <SessionsAccount
              name="deviceId"
              placeholder="Account"
              disabled={loading}
            />
            <SessionsRoom name="room" placeholder="Room" disabled={loading} />
            <SessionsRoomType
              name="gameType"
              placeholder="Room type"
              disabled={loading}
            />
            <SessionsDateStarted disabled={loading} />
            <SessionsDateEnded disabled={loading} />
          </div>
          <div className="table-filters-r">
            {!isAgentOrSubagent && (
              <FilterCheckBox
                name="showEmpty"
                disabled={loading}
                searchParams={searchParams}
                setSearchParam={setSearchParam}
                setSearchParams={setSearchParams}
              >
                {t("empty")}
              </FilterCheckBox>
            )}
            <FilterCheckBox
              name="onlyActive"
              disabled={loading}
              searchParams={searchParams}
              setSearchParam={setSearchParam}
              setSearchParams={setSearchParams}
            >
              {t("active")}
            </FilterCheckBox>
            <button
              disabled={
                !(searchParams?.dateStarted && searchParams?.dateEnded) || loading
              }
              onClick={async () => {
                await getFileForDownload();
              }}
              className="p-btn grayBg"
              css={css`
                &&& {
                  opacity: ${!(
                    searchParams?.dateStarted && searchParams?.dateEnded
                  ) || loading
                    ? 0.5
                    : 1};
                }
              `}
            >
              {t("downloadCSV")}
            </button>
          </div>
        </div>
        <div className="content-table">
          {sessionPageStore.sessions.length ? (
            <div className="table-th">
              <div>{t("accounts")}</div>
              <div>{t("pid")}</div>
              <div>{t("room")}</div>
              <div>{t("aid")}</div>
              <div>{t("cid")}</div>
              <div>{t("type")}</div>
              <div>{t("sessionTime")}</div>
              <div>{t("fuelBilled")}</div>
              <div>{t("hands")}</div>
              <div>{t("level")}</div>
              <div>{t("win")}</div>
              <div>{t("rake")}</div>
              <div>{t("insur")}</div>
              <div>{t("errors")}</div>
              <div>{t("server")}</div>
            </div>
          ) : (
            ""
          )}
          {sessionPageStore.sessions.map((item, index) => (
            <div className="table-tr" key={index}>
              <div>{formatNumberWithSpaces(item.session.deviceId)}</div>
              <div>{item.session.pid}</div>
              <div>{item.session.lastRoom}</div>
              <div>{item.session.alliance}</div>
              <div>{item.session.club}</div>
              <div>{item.session.gameType}</div>
              <div>
                {formatSessionTime(item.session.started, item.session.ended)}
              </div>
              <div>{item.session.billed.toFixed(2)}</div>
              <div>{item.session.handsCnt}</div>
              <div>{item.session.lastBb.split(".")[0]}</div>
              <div>{item.session.winlose}</div>
              <div>{item.session.rake}</div>
              <div>{item.session.insurance}</div>
              <div>{item.totalErrors}</div>
              <div>{item.session.server}</div>
            </div>
          ))}
        </div>
        {sessionPageStore.currentPage < sessionPageStore.totalPages - 1 && (
          <div className="more">
            <button
              onClick={loadMore}
              className={`p-btn ${
                sessionPageStore.loading && "loading"
              } grayBg more-btn`}
            >
              {sessionPageStore.loading ? "" : "Load more"}
            </button>
          </div>
        )}
      </div>
    </SimpleBar>
  );
}

export default SessionsPage;
