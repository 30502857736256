const configTranslation = {
  en: {
    brain_type: "AI type",
    stats_profile: "AI profile",
    select_enable: "Table select",
    auto_rebuy: "Auto rebuy",
    rebuy_trigger: "Rebuy trigger",
    rebuy_amount: "Rebuy amount",
    rebuy_step: "Rebuy step",
    insur_flop_behaviour: "Insurance flop",
    insur_flop_freq: "frequency",
    insur_flop_amount: "amount",
    insur_turn_freq: "frequency",
    insur_turn_amount: "amount",
    exit_enable: "Exit rules",
    exit_min_hands: "Min hands",
    exit_min_hands_at_profit: "Min hands at profit",
    exit_win_bb: "By win BB",
    exit_lose_bb: "By lose bb",
    exit_fullstack_lose: "By full stack lose",
    exit_max_hands: "By max hands",
    exit_lifetime: "By table lifetime",
    timings_enable: "Custom timings",
    timings_bets: "Bets",
    timings_calls: "Calls",
    timings_checks: "Checks",
    timings_folds: "Folds",
    timings_raises: "Raises",
    auto_buyin_enable: "Auto buy-in",
    limit: "Limit",
    buyin_club_id: "Club ID",
    buyin_amount: "Buy-in amount",
    buyin_step: "Buy-in step",
    maxbot_amount: "Maxbot",
    show_tips_enable: "Show tips",
    tips_lifetime: "Tips lifetime",
    opponents_amount: "Opponents amount",
    table_lifetime: "Table lifetime",
    auto_room_coins_check: "Balance check",
    auto_alc_return: "Balance return after game",
    balance_operations_enable: "Balance operations",
    auto_table_selection: "Automatic table selection",
    scheduler_enable: "Game scheduler",
    timings_insur_buy: "Insurance buy",
    timings_insur_fold: "Insurance cancel",
    insur_turn_behaviour: "Insurance turn",
    exit_min_opponents: "By min opponents",
    exit_table_select: "By table select",
    scheduler_start_time: "Start time",
    scheduler_play_time: "Play time",
    auto_cancel: "Auto cancel",
    buy_manually: "Buy manually",
    buy_automatically: "Buy automatically",
    save: "Save",
    reset: "Reset",
    accountConfiguration: "Settings",
    usual: "Usual",
    vip: "VIP",
    accept: "Accept",
    // fixme data has "title" for this
    use_quickbuttons: "Use quickbuttons",
  },
  zh: {
    brain_type: "AI形式",
    stats_profile: "AI数据",
    select_enable: "桌子挑选",
    auto_rebuy: "自动补码",
    rebuy_trigger: "补码低界限",
    rebuy_amount: "补码量 ",
    rebuy_step: "补码散布",
    insur_flop_behaviour: "翻牌保险",
    insur_flop_freq: "翻牌保险频率",
    insur_flop_amount: "翻牌保险范围",
    insur_turn_freq: "转牌保险频率",
    insur_turn_amount: "转牌保险范围",
    exit_enable: "退出条件",
    exit_min_hands: "最小手数量退出",
    exit_min_hands_at_profit: "最小盈利手术量退出",
    exit_win_bb: "赢多少退出",
    exit_lose_bb: "输多少退出",
    exit_fullstack_lose: "全输退出",
    exit_max_hands: "最大手数退出",
    exit_lifetime: "桌子结束前退出",
    timings_enable: "自定义时间安排",
    timings_bets: "Bet 时长",
    timings_calls: "Call 时长",
    timings_checks: "Check 时长",
    timings_folds: "Fold 时长",
    timings_raises: "Raise 时长",
    auto_buyin_enable: "自动带入",
    show_tips_enable: "显示提示",
    tips_lifetime: "提示时长",
    limit: "级别",
    buyin_club_id: "俱乐部ID",
    buyin_amount: "带入数量",
    buyin_step: "带入散布",
    maxbot_amount: "最大机器量",
    opponents_amount: "最大反对者量",
    table_lifetime: "桌子时长",
    auto_room_coins_check: "余额检查",
    auto_alc_return: "打完后退余额",
    balance_operations_enable: "余额操作",
    auto_table_selection: "自动选局",
    scheduler_enable: "游戏调度",
    timings_insur_buy: "购买保险时长",
    timings_insur_fold: "取消保险时长",
    insur_turn_behaviour: "转牌保险",
    exit_min_opponents: "由于最小反对者量退出",
    exit_table_select: "由于自动选卓退出",
    scheduler_start_time: "开始时间",
    scheduler_play_time: "打牌阶段",
    auto_cancel: "自动取消",
    buy_manually: "手动购买",
    buy_automatically: "自动购买",
    save: "保存",
    reset: "重置",
    accountConfiguration: "设置",
    usual: "通常",
    vip: "VIP",
    accept: "接受",
    use_quickbuttons: "用快捷按钮",
  },
};

export default configTranslation;
