import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";

import { useConfigStore } from "../../entities/config/ConfigStore";
import { useBotModeOptions } from "../../entities/config/use-bot-mode-options";
import { useEventBus } from "../../features/event-bus/use-event-bus";
import { ReactComponent as SvgClose } from "../../images/close.svg";
import { DynamicInputList } from "../../shared/ui/dynamic-input-list";
import { useAccountsStore } from "../../store/AccountsStore";
import { useHeaderStore } from "../../store/HeaderStore";
import { useSessionStore } from "../../store/SessionStore";
import Select from "../Select";
import { ConfigModalReset } from "./config-modal-reset";
import { ConfigModalSave } from "./config-modal-save";
import s from "./config-modal.scss";

export const ConfigModal = ({ closeModal }) => {
  const {
    getConfig,
    config,
    saveConfig,
    resetUserValues,
    selectedRoom,
    selectedRoomType,
    selectedBotMode,
    setSelectedRoom,
    setSelectedRoomType,
    setSelectedBotMode,
    getAllowedRooms,
    userValues,
    totalPriceAdd,
    allowedRooms,
    setTotalPriceAddWithHint,
    resetAll,
  } = useConfigStore();

  const sessionStore = useSessionStore();
  const accountStore = useAccountsStore();
  const headerStore = useHeaderStore();

  const { t } = useTranslation("config");

  useEffect(() => {
    getAllowedRooms(headerStore.currentUser);
  }, [getAllowedRooms, headerStore.currentUser]);

  const [isLoading, setIsLoading] = useState(false);

  const roomOptions = useMemo(() => {
    return [
      ...Object.entries(allowedRooms ?? {}).map((item) => {
        // console.log(item[1].icon)
        return {
          value: item[0],
          label: item[0],
          icon: item[1].icon,
        };
      }),
    ];
  }, [allowedRooms]);

  const roomTypeOptions = useMemo(() => {
    return [
      ...Object.keys(allowedRooms?.[selectedRoom] ?? {})
        .filter((item) => item !== "icon")
        .map((item) => ({
          value: item,
          label: item,
        })),
    ];
  }, [allowedRooms, selectedRoom]);

  const botModeOptions = useBotModeOptions();

  const simpleBarRef = useRef(null);

  const [errors, setErrors] = useState([]);

  const getError = useCallback(
    ({ fieldName }) => {
      return errors?.find((i) => i?.fieldName === fieldName);
    },
    [errors]
  );

  const setError = useCallback(({ fieldName, message, elementRef }) => {
    setErrors((current) => {
      return [...current, { fieldName, message, elementRef }];
    });
  }, []);

  const removeError = useCallback(({ fieldName, message }) => {
    setErrors((current) => {
      return current?.filter((i) => i?.fieldName !== fieldName);
    });
  }, []);

  const scrollToFirstError = useCallback(() => {
    try {
      errors?.[0]?.elementRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } catch (error) {
      //
    }
  }, [errors]);

  const canSave = useMemo(() => {
    return !errors?.length;
  }, [errors?.length]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabSelect = useCallback(
    (index) => {
      if (!canSave) {
        scrollToFirstError();
        return false;
      }
      setSelectedTabIndex(index);
      setErrors((prevErrors) => []);
    },
    [canSave, scrollToFirstError]
  );

  useEffect(() => {
    if (simpleBarRef?.current) {
      setTimeout(() => {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }, 0);
    }
  }, [config, selectedTabIndex]);

  const handleRoomSelect = (option) => {
    const { getRoomSelection, setRoomSelection } = useConfigStore.getState();
    const previousSelection = getRoomSelection(option.value);
    setSelectedRoom(option.value);
    const newRoomTypes = Object.keys(allowedRooms[option.value]).filter(
      (item) => item !== "icon"
    );
    const newSelectedRoomType =
      previousSelection.roomType && newRoomTypes.includes(previousSelection.roomType)
        ? previousSelection.roomType
        : newRoomTypes.length > 0
        ? newRoomTypes[0]
        : "";
    const newBotModes = newSelectedRoomType
      ? Object.keys(allowedRooms[option.value][newSelectedRoomType] || {})
      : [];
    const newSelectedBotMode =
      previousSelection.botMode && newBotModes.includes(previousSelection.botMode)
        ? previousSelection.botMode
        : newBotModes.length > 0
        ? newBotModes[0]
        : "";
    setSelectedRoomType(newSelectedRoomType);
    setSelectedBotMode(newSelectedBotMode);
    setRoomSelection(option.value, newSelectedRoomType, newSelectedBotMode);
    setErrors((prevErrors) => []);
  };

  const handleRoomTypeSelect = (option) => {
    const { setRoomSelection } = useConfigStore.getState();
    const previousBotMode = selectedBotMode ? selectedBotMode : "";
    setSelectedRoomType(option.value);
    const newBotModes = Object.keys(allowedRooms[selectedRoom][option.value] || {});
    const newSelectedBotMode = newBotModes.includes(previousBotMode)
      ? previousBotMode
      : newBotModes.length > 0
      ? newBotModes[0]
      : "";
    setSelectedBotMode(newSelectedBotMode);
    setRoomSelection(selectedRoom, option.value, newSelectedBotMode);
    setErrors((prevErrors) => []);
  };

  const handleBotModeSelect = (option) => {
    const { setRoomSelection } = useConfigStore.getState();
    setSelectedBotMode(option.value);
    const oldHint =
      botModeOptions.find((o) => o.value === selectedBotMode)?.hint || 0;
    const newHint = option.hint || 0;
    setTotalPriceAddWithHint(newHint, oldHint);
    setRoomSelection(selectedRoom, selectedRoomType, option.value);
    setErrors((prevErrors) => []);
  };

  const configSave = useCallback(async () => {
    if (!canSave) {
      scrollToFirstError();
      return;
    }
    setIsLoading(true);
    const result = await saveConfig(
      accountStore.selectedAccounts,
      sessionStore.data.partId
    );
    setIsLoading(false);
    if (result?.data?.status === "SUCCESS") {
      closeModal();
    }
    if (result?.data?.status === "ERROR") {
      toast?.error(`${result?.data?.status} : ${result?.data?.code}`, {
        delay: 0,
        autoClose: 10000,
        position: "top-center",
      });
    }
  }, [
    accountStore.selectedAccounts,
    canSave,
    closeModal,
    saveConfig,
    scrollToFirstError,
    sessionStore.data.partId,
  ]);

  const [canRender, setCanRender] = useState(false);

  // fixme temp
  useEffect(() => {
    resetAll();
    setCanRender(true);
    // eslint-disable-next-line
  }, []);

  const configFetch = useCallback(
    async (selectedRoom, selectedRoomType, selectedBotMode) => {
      if (selectedRoom && selectedRoomType && selectedBotMode) {
        setIsLoading(true);
        return getConfig(
          accountStore.selectedAccounts,
          selectedRoom,
          selectedRoomType,
          selectedBotMode
        ).then(() => {
          setIsLoading(false);
        });
      }
    },
    [accountStore.selectedAccounts, getConfig]
  );

  useEffect(() => {
    // fixme temp
    if (canRender) {
      configFetch(selectedRoom, selectedRoomType, selectedBotMode);
    }
  }, [canRender, configFetch, selectedBotMode, selectedRoom, selectedRoomType]);

  useEventBus(["CONFIG_CHANGED"], (message) => {
    console.log("xxxxxxxxxxxxx ===================== CONFIG_CHANGED ", message);
    // configFetch(selectedRoom, selectedRoomType, selectedBotMode);
  });

  return !canRender ? (
    <></>
  ) : (
    <Tabs onSelect={handleTabSelect}>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          width: 600px;
          padding: 30px 50px;
        `}
      >
        {/*header*/}
        <div
          css={css`
            color: var(--text-color);
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 500;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-transform: uppercase;
          `}
        >
          {t("accountConfiguration")}
          <div className="window_close" onClick={closeModal}>
            <SvgClose />
          </div>
        </div>
        {/*room*/}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
          `}
        >
          <div className={s.selectRoom}>
            <Select
              options={roomOptions}
              onSelect={handleRoomSelect}
              selected={roomOptions.find((option) => option.value === selectedRoom)}
              totalPrice={totalPriceAdd}
            />
          </div>
          <div className={s.selectsGroup}>
            <div className={s.select}>
              <Select
                options={roomTypeOptions}
                onSelect={handleRoomTypeSelect}
                selected={roomTypeOptions.find(
                  (option) => option.value === selectedRoomType
                )}
              />
            </div>
            <div className={s.select}>
              <Select
                options={botModeOptions}
                onSelect={handleBotModeSelect}
                selected={botModeOptions.find(
                  (option) => option.value === selectedBotMode
                )}
              />
            </div>
          </div>
        </div>
        {/* usual / vip */}
        <TabList className={s.tabs}>
          {config?.[0] && (
            <Tab
              className={`${s.tab} tabControl`}
              selectedClassName={s.activeTab}
              style={{
                minHeight: "30px",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border:
                  selectedTabIndex === 0 && errors?.length
                    ? "1px solid red"
                    : "transparent",
              }}
            >
              {t("usual")}
            </Tab>
          )}
          {config?.[1] && (
            <Tab
              className={`${s.tab} tabControl`}
              selectedClassName={s.activeTab}
              style={{
                minHeight: "30px",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border:
                  selectedTabIndex === 1 && errors?.length
                    ? "1px solid red"
                    : "transparent",
              }}
            >
              {t("VIP")}
            </Tab>
          )}
        </TabList>
        <div className="m-settings-content">
          <SimpleBar
            className={s.simpleBar}
            ref={simpleBarRef}
            forceVisible="y"
            autoHide={false}
          >
            {config?.[0] && (
              <TabPanel>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    padding: 0 1rem;
                  `}
                >
                  <DynamicInputList
                    config={config[0]}
                    getError={getError}
                    setError={setError}
                    removeError={removeError}
                  />
                </div>
              </TabPanel>
            )}
            {config?.[1] && (
              <TabPanel>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    padding: 0 1rem;
                  `}
                >
                  <DynamicInputList
                    config={config[1]}
                    getError={getError}
                    setError={setError}
                    removeError={removeError}
                  />
                </div>
              </TabPanel>
            )}
          </SimpleBar>
        </div>
        <div className={s.controlButtons}>
          <ConfigModalReset disabled={isLoading} onClick={() => resetUserValues()} />
          <ConfigModalSave disabled={isLoading} onClick={configSave} />
        </div>
      </div>
    </Tabs>
  );
};
