import { create } from "zustand";

import { api } from "../api/requests";
import {
  formatDateShort,
  parseJsonStringToArray,
  parsedFuelHistory,
} from "../shared/utils/utils";

export const usePartnersStore = create((set, get) => ({
  searchParams: {},
  partners: [],
  partIdSelect: null,
  dateFrom: new Date(),
  dateTo: new Date(),
  loading: false,
  createLoading: false,
  setDateFrom: (date) => {
    set({ dateFrom: date });
  },
  setDateTo: (date) => {
    set({ dateTo: date });
  },
  setPartIdSelect: (partId) => {
    set({ partIdSelect: partId });
  },
  fetchPartners: async ({ page = 0, role } = {}) => {
    const { partIds, dateStarted, dateEnded } = get()?.searchParams || {};
    if (!partIds) {
      return;
    }

    let currentPage = 0;
    set({ partners: [], loading: true });

    let request = () => {
      console.log("xxxxxxxxxxxxxxxxxxxx AGENT ", role);
      return api.partnersReq({
        offset: currentPage * 50,
        partId: partIds,
        dateStarted: formatDateShort(dateStarted),
        dateEnded: formatDateShort(dateEnded),
      });
    };

    if (role === "SUBAGENT") {
      request = () => {
        console.log("xxxxxxxxxxxxxxxxxxxx SUBAGENT ", role, partIds);
        return api.retailersReq({
          offset: currentPage * 50,
          partId: partIds,
          dateStarted: formatDateShort(dateStarted),
          dateEnded: formatDateShort(dateEnded),
        });
      };
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const response = await request();
        const parsedData = parseJsonStringToArray(response.data);
        let totalPages = parsedData.find(
          (item) => item.name === "pagination"
        ).totalPages;
        set((state) => {
          return {
            partners: state.partners.concat(
              parsedData.filter((item) => item.name === "partner")
            ),
          };
        });
        if (currentPage >= totalPages - 1) {
          break;
        }
        currentPage++;
      } catch (error) {
        console.error("Error: ", error);
        break;
      }
    }
    set({ loading: false });
  },
  transferFuel: async (srcPartId, dstPartId, amount) => {
    try {
      const response = await api.fuelTransferReq({
        srcPartId: srcPartId,
        dstPartId: dstPartId,
        fuelQty: amount,
      });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        return parsedData;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  fetchFuelHistory: async (partId) => {
    try {
      const response = await api.fuelHistoryReq({ srcPartId: partId });
      const parsedData = parseJsonStringToArray(response.data);
      return parsedFuelHistory([parsedData]);
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  generateAuthKey: async (partId) => {
    try {
      const response = await api.generateSubpartnerAuthkeyReq({ partId: partId });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        return parsedData;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  deletePartner: async (partId) => {
    try {
      const response = await api.deletePartnersReq({ partIds: [partId] });
      const parsedData = response.data;
      return parsedData;
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  createPartner: async (role, param) => {
    set({ createLoading: true });
    let request = () => api.createPartnerReq({ notes: "", contact: param });
    if (role === "SUBAGENT") {
      request = () => api.createRetailerReq({ notes: "", partId: param });
    }
    try {
      const response = await request();
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        const newPartner = {
          partId: parsedData.newPartner.partId,
          partnerName: parsedData.newPartner.partnerName,
          authKey: parsedData.partnerAuthKey,
          totalAccounts: 0,
          fuelBilled: 0,
          totalHands: 0,
          totalWinLose: 0,
          totalRake: 0,
          totalInsurance: 0,
          totalErrors: 0,
          totalFuel: 0,
        };
        set((state) => ({ partners: [...state.partners, newPartner] }));
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    set({ createLoading: false });
  },
  updateNote: async (partId, note) => {
    try {
      const response = await api.editNoteReq({ partId, notes: note });
      const parsedData = response.data;
      // console.log('accId: ', accId, 'note: ', note, 'data: ', parsedData)
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  setSearchParams: async (searchParams = {}) => {
    set({ searchParams });
  },
  setSearchParam: async (key, value) => {
    if (typeof key === "string") {
      set(({ searchParams } = {}) => ({
        searchParams: {
          ...searchParams,
          [key]: value,
        },
      }));
    }
  },
}));
