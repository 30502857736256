import { diff } from "deep-object-diff";
import { create } from "zustand";

import { api } from "../../api/requests";

export const useConfigStore = create((set, get) => ({
  config: {},
  userValues: {},
  allowedRooms: null,
  selectedRoom: "",
  selectedRoomType: "",
  selectedBotMode: "",
  totalPriceAdd: 0,
  roomSelections: {},

  applyStatuses: {},
  groupsToSave: [],

  addGroupToSave: (groupId) => {
    const { groupsToSave } = get();
    if (groupId && !groupsToSave.includes(groupId)) {
      set({
        groupsToSave: [...groupsToSave, groupId],
      });
    }
  },

  removeGroupToSave: (groupId) => {
    const { groupsToSave } = get();
    if (groupId && groupsToSave.includes(groupId)) {
      set({
        groupsToSave: groupsToSave?.filter((id) => id !== groupId),
      });
    }
  },

  resetAll: () =>
    set({
      config: {},
      userValues: {},
      applyStatuses: {},
      allowedRooms: null,
      selectedRoom: "",
      selectedRoomType: "",
      selectedBotMode: "",
      totalPriceAdd: 0,
      roomSelections: {},
      groupsToSave: [],
    }),

  setSelectedRoom: (room) => set({ selectedRoom: room }),
  setSelectedRoomType: (roomType) => set({ selectedRoomType: roomType }),
  setSelectedBotMode: (botMode) => set({ selectedBotMode: botMode }),

  setRoomSelection: (room, roomType, botMode) =>
    set((state) => ({
      roomSelections: {
        ...state.roomSelections,
        [room]: { roomType, botMode },
      },
    })),

  getRoomSelection: (room) => get().roomSelections[room] || {},

  getAllowedRooms: async (partId) => {
    try {
      const response = await api.getAllowedRoomsReq({
        partId: partId,
      });
      const parsedData = response.data;
      set({ allowedRooms: parsedData.allowedRooms });
    } catch (error) {
      console.error("Error: ", error);
    }
  },

  setTotalPriceAdd: (priceAddChange) =>
    set((state) => ({
      totalPriceAdd: state.totalPriceAdd + priceAddChange,
    })),

  setTotalPriceAddWithHint: (newHint, oldHint) =>
    set((state) => ({
      totalPriceAdd: state.totalPriceAdd - oldHint + newHint,
    })),

  setApplyStatus: (key, isChecked) =>
    set((state) => ({
      applyStatuses: { ...state.applyStatuses, [key]: isChecked },
    })),

  getConfig: async (accIds, roomName, gameType, mode) => {
    try {
      const response = await api.cfgReq({
        accIds: accIds,
        roomName: roomName,
        gameType: gameType,
        mode: mode,
      });
      if (response?.data?.status === "SUCCESS") {
        const { userFormat } = get();
        set({
          config: response?.data?.cfgs,
          // userValues: userFormat(response?.data?.cfgs),
          userValues: userFormat([
            ...(response?.data?.cfgs?.[0] || []),
            ...(response?.data?.cfgs?.[1] || []),
          ]),
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },

  userFormat: (configs = []) => {
    return configs?.reduce((acc, configItem) => {
      return {
        ...acc,
        [configItem?.confId]:
          typeof configItem?.currentValue === "string" && !!configItem?.currentValue
            ? configItem?.currentValue
            : configItem?.defaultValue,
      };
    }, {});
  },

  saveFormat: (userValues) => {
    return Object.entries(userValues).map(([key, value]) => {
      return {
        confId: Number(key),
        currentValue: value,
      };
    });
  },

  getConfigGroup: (groupId) => {
    const { config } = get();
    return [...(config?.[0] || []), ...(config?.[1] || [])]?.filter(
      (item) => item?.confId === groupId || item?.parentConfId === groupId
    );
  },

  getUserGroup: (groupId) => {
    const { getConfigGroup, userValues } = get();
    return (getConfigGroup(groupId) || []).reduce((acc, item) => {
      return {
        ...acc,
        [item?.confId]: userValues?.[item?.confId],
      };
    }, {});
  },

  isGroup(confId) {
    const { config } = get();
    return [...(config?.[0] || []), ...(config?.[1] || [])]?.some(
      (item) => item.parentConfId === confId
    );
  },

  setUserValue: (confId, value) => {
    const {
      config,
      groupsToSave,
      getConfigGroup,
      getUserGroup,
      userFormat,
      isGroup,
    } = get();

    const configItem = [...(config?.[0] || []), ...(config?.[1] || [])]?.find(
      (item) => item?.confId === confId
    );

    if (configItem?.parentConfId || isGroup(confId)) {
      const itemId = isGroup(confId) ? confId : configItem.parentConfId;
      const initialGroup = userFormat(getConfigGroup(itemId));
      const userGroup = getUserGroup(itemId);
      const difference = diff(initialGroup, { ...userGroup, [itemId]: value });

      if (Object.keys(difference)?.length) {
        if (!groupsToSave.includes(itemId)) {
          set({
            groupsToSave: [...groupsToSave, itemId],
          });
        }
      } else {
        // track only enable (by spec)
        // if (groupsToSave.includes(itemId)) {
        //   set({
        //     groupsToSave: groupsToSave?.filter((groupId) => groupId !== itemId),
        //   });
        // }
      }
    }

    set((state) => ({
      userValues: { ...state.userValues, [confId]: value },
    }));
  },

  resetUserValues: (groupId) => {
    const { config, userValues, userFormat, getConfigGroup } = get();
    if (groupId) {
      set({
        userValues: { ...userValues, ...userFormat(getConfigGroup(groupId)) },
      });
      return;
    }
    set({
      userValues: userFormat([...(config?.[0] || []), ...(config?.[1] || [])]),
      groupsToSave: [],
      applyStatuses: {},
    });
  },

  saveConfig: async (accIds, partId) => {
    try {
      const {
        config,
        userValues,
        userFormat,
        saveFormat,
        groupsToSave,
        getUserGroup,
        selectedBotMode,
      } = get();

      if (!selectedBotMode) {
        return;
      }

      const initialUserValues = userFormat([
        ...(config?.[0] || []),
        ...(config?.[1] || []),
      ]);

      const difference = diff(initialUserValues, userValues);

      const groupsToSaveUserValues = groupsToSave?.reduce((acc, groupId) => {
        return {
          ...acc,
          ...(getUserGroup(groupId) || {}),
        };
      }, {});

      const saveValues = {
        ...groupsToSaveUserValues,
        ...difference,
      };

      const workingMode = {
        0: "MANUAL",
        1: "SEMI",
        2: "AUTO",
      }[selectedBotMode];

      if (Object.keys(saveValues)?.length) {
        return await api.cfgSaveReq({
          accIds: accIds,
          partId: partId,
          cfgs: saveFormat(saveValues),
          workingMode,
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
}));
