import Cookies from "js-cookie";
import { create } from "zustand";

import { api, sessionStore } from "../api/requests";
import { getPartnersIds } from "../shared/utils/utils";

const userData = JSON.parse(sessionStorage.getItem("userData"));

export const useSessionStore = create((set, get) => ({
  data: userData,
  currentUser: userData ? userData.partId : null,
  subPartners: null,
  role: userData ? userData.role : null,
  apkUrl: sessionStorage.getItem("apkUrl"),
  agentCode: sessionStorage.getItem("agentCode"),
  theme: Cookies.get("isDarkMode") === "1" ? "dark" : "light",
  loginLoading: false,
  setTheme: (theme) => set({ theme: theme }),
  getSubpartnersReq: async () => {
    try {
      const response = await api.getSubpartnersReq();
      const parsedData = response?.data;
      if (parsedData.status === "SUCCESS") {
        set({ subPartners: getPartnersIds(parsedData.subPartners) });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  login: async (agentCode) => {
    set({ loginLoading: true });
    try {
      const response = await api.authenticate({ partnerAuthKey: agentCode });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        try {
          const response = await api.loggedPartnerDataReq({});
          const parsedData = response.data;
          if (parsedData.status === "SUCCESS") {
            sessionStorage.setItem("userData", JSON.stringify(parsedData));
            sessionStorage.setItem("agentCode", agentCode);
            set({
              loginLoading: false,
              data: parsedData,
              currentUser: parsedData.partId,
              role: parsedData.role,
              agentCode: agentCode,
            });
          }
        } catch (error) {
          console.error("Error: ", error);
        }
        try {
          const resp = await api.getPokerEyeVersion();
          const parsedData = resp.data;
          if (parsedData.status === "SUCCESS") {
            set({ apkUrl: parsedData.url });
            sessionStorage.setItem("apkUrl", parsedData.url);
          }
        } catch (error) {
          console.error("Error: ", error);
        }
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    set({ loginLoading: false });
  },
  getUserData: async () => {
    try {
      const response = await api.loggedPartnerDataReq({});
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        try {
          window?.sessionStorage?.setItem("userData", JSON.stringify(parsedData));
        } catch (err) {
          //
        }
        set({
          data: parsedData,
          currentUser: parsedData.partId,
          role: parsedData.role,
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  isLoggedIn: async () => {
    try {
      await api.isLoggedIn();
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  setNewAgentCode: (newCode) => {
    set({ agentCode: newCode });
    sessionStorage.setItem("agentCode", newCode);
  },
  logout: async () => {
    try {
      const response = await api.clearCookies();
      if (response.data.status === "SUCCESS") {
        sessionStorage.clear();
        return response.data.status;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  setCurrentUser: (partId, role) => {
    set({ currentUser: partId, role });
  },
  resetUserData: () => {
    sessionStorage.clear();
    set({ data: null });
  },
  getCurrentUser: () => get().currentUser,
  setRole: (role) => set({ role: role }),
}));

sessionStore.current = useSessionStore;
