import React, { useCallback, useMemo } from "react";

import s from "../../../components/modal/config-modal.scss";
import { useConfigStore } from "../../../entities/config/ConfigStore";

export const DynamicInputRange = React.forwardRef(function DynamicInputRange(
  { configItem = {}, disabled, error },
  ref
) {
  const { minLimit, maxLimit } = configItem;

  const userValue = useConfigStore(
    (config) => config?.userValues[configItem?.confId]
  );

  const { setUserValue } = useConfigStore();

  const userRangeValues = useMemo(() => userValue?.split(";"), [userValue]);

  const defaultRangeValues = useMemo(
    () => configItem?.defaultValue?.split(";"),
    [configItem?.defaultValue]
  );

  const [minValue = "", maxValue = ""] = userRangeValues || defaultRangeValues || [];

  const handleRangeBlur = useCallback(
    (value, isMin) => {
      let correctedValue = Number(value);
      if (minLimit !== null) {
        correctedValue = Math.max(correctedValue, minLimit);
      }
      if (maxLimit !== null) {
        correctedValue = Math.min(correctedValue, maxLimit);
      }
      if (isMin) {
        setUserValue(configItem.confId, `${correctedValue};${maxValue}`);
      } else {
        setUserValue(configItem.confId, `${minValue};${correctedValue}`);
      }
    },
    [configItem.confId, maxLimit, maxValue, minLimit, minValue, setUserValue]
  );

  return (
    <div className={s.interactiveField} ref={ref}>
      <input
        className={`${s.rangeInput} ${disabled && s.disabled} interactiveInput`}
        type="number"
        value={minValue}
        placeholder="MIN"
        disabled={disabled}
        onChange={(e) => {
          setUserValue(configItem.confId, `${e?.target?.value};${maxValue}`);
        }}
        onBlur={(e) => {
          handleRangeBlur(e.target.value, true);
        }}
      />
      <input
        className={`${s.rangeInput} ${disabled && s.disabled} interactiveInput`}
        type="number"
        value={maxValue}
        placeholder="MAX"
        disabled={disabled}
        onChange={(e) => {
          setUserValue(configItem.confId, `${minValue};${e?.target?.value}`);
        }}
        onBlur={(e) => {
          handleRangeBlur(e.target.value, false);
        }}
      />
    </div>
  );
});
